import React from 'react';
import Layout from 'components/Layout';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
// import Faq from 'components/Faq';
import ArchivePostSingle from 'components/ArchivePostSingle';
import Meta from 'components/Meta';
import SupportHeader from 'components/SupportHeader';
import Scrollspy from 'components/Scrollspy';
// import theme from 'styles/theme';
// import Main from 'styles/supportInner';
// import { BlogListSupport } from 'styles/support';
import SupportInnerNav from '../../components/SupportInnerNav/SupportInnerNav';
import 'styles/supportInner.scss';
const SupportInvitePeople = ({
  data: {
    allContentfulBlogPost: { edges: allPosts = [] },
  },
  location,
}) => (
  <Layout hideHeader location={location}>
    {({ toggleContactUs }) => (
      <>
        <Meta
          title="Support documentation 🔨 | ruttl"
          description="ruttl simplifies website reviews, offering easy commenting and live editing. Invite stakeholders for precise, contextual feedback on pixel-perfect designs."
          url="https://ruttl.com/support/"
        />
        <main className="supportinner-styed-main">
          <SupportHeader toggleContactUs={toggleContactUs} />
          <section className="supportinner-hero">
            <div className="container">
              <h1>Invite people:</h1>
            </div>
          </section>
          <SupportInnerNav />
          <section className="support-inner-block">
            <Scrollspy>
              <div className="flex-1 support-inner-content">
                <img src="/assets/img/support-get-started.png" alt="" />

                <h2
                  data-spy-title="Invite team members with email ID"
                  data-spy-id="invite-team-members-with-email-id">
                  Invite team members with email ID
                </h2>

                <p>
                  Open the project which you would like to share. Click on the
                  ‘Share’ button on the top right side, copy and paste the email
                  ID of the user with whom you would like to share this project,
                  and send an invite. The person will receive an email, with the
                  help of which he/she will be able to access the project in
                  his/her available access.
                </p>

                <h3
                  data-spy-title="Share with client (no login required to leave comments)"
                  data-spy-id="share-with-client">
                  Share with client (no login required to leave comments)
                </h3>

                <p>
                  This is especially useful for sharing a project with the
                  clients, since clients want to see progress on the project
                  without logging into any platform. Inside any project, go to
                  the top right hand corner, click on ‘Share’. On the next
                  pop-up, open Links, check on ‘Guest Commenting’ and toggle the
                  Shareability to on. Copy the project link by clicking on
                  ‘Link’ and share this with your client. The client will then
                  be able to leave comments, mark other collaborators, see all
                  the comments, replies to comments and edits on the webpages of
                  the project.
                </p>
                <h3
                  data-spy-title="Enable access to a single webpage or all webpages inside the same project"
                  data-spy-id="enable-access-to-a-single-webpage">
                  Enable access to a single webpage or all webpages inside the
                  same project
                </h3>

                <p>
                  Inside any project, go to the top right hand corner, click on
                  ‘Share’. On the next pop-up, open Links, check on ‘Show other
                  pages’ and toggle the Shareability to on. Copy the project
                  link by clicking on ‘Link’ and share this with your client or
                  any external person. This person shall then be able to view
                  all pages and images in this project. Disabling the tick on
                  ‘Show other pages’ shall only show the page from which you’re
                  sharing the link.
                </p>
              </div>
            </Scrollspy>
          </section>
          <main className="blog-list-support">
            <section
              className="bg-lightGrey"
              style={{
                // backgroundColor: theme.colors.lightGrey,
                padding: '10px 0',
              }}>
              <div className="container">
                <div className="other-articles-block">
                  <h3>Popular Articles</h3>
                  <ul>
                    {allPosts.map(({ node }) => (
                      <li key={node.id}>
                        <ArchivePostSingle
                          link={`/blog/${node.slug}/`}
                          title={node.title}
                        />
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </section>
          </main>
          {/* <Faq id="faq" /> */}
        </main>
      </>
    )}
  </Layout>
);

SupportInvitePeople.propTypes = {
  data: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

export default SupportInvitePeople;

export const pageQuery = graphql`
  query SupportInvitePeopleQuery {
    allContentfulBlogPost(
      filter: { knowledgeBox: { eq: false } }
      limit: 10
      sort: { order: DESC, fields: publishDate }
    ) {
      edges {
        node {
          id
          title
          slug
          publishDate(formatString: "MMM DD, YYYY")
          archiveThumbnail {
            fluid(
              maxWidth: 600
              maxHeight: 456
              quality: 70
              resizingBehavior: THUMB
            ) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
          excerpt {
            excerpt
          }
          knowledgeBox
          category {
            name
          }
        }
      }
    }
  }
`;
